import React, { useState, useContext } from 'react';

import { IntlUtility } from '../../../helpers';
import { Image } from '../../core';
import styles from './styles.module.css';
import classNames from 'classnames';
import CustomSelect from '../../core/CustomSelect';
import { thriftBagVariantIDs } from '../../../utils/constants';
import Link from 'next/link';
import { WishlistContext } from '../../../context/WishlistContext';
import { roundNumberDownToNearest5 } from '../../../utils/helpers';
import { MAX_BAGS_PER_ORDER } from '../../../consts/bags';
import { Help } from '../../account/Payout/Help';
import { useStoryblokConfig } from '../../../context/ConfigContext';
import { CartLine } from '../../../data-fetching/cart/useCart';
import { toPDPtitle } from '../../sections/ProductSingle/utils/helpers';
import { useCartLine } from '../../../data-fetching/cart/useCartLine';
import {
  daysDiffToString,
  daysRemainingForOffer,
  offerHasExpired,
  useActiveOfferForItem,
} from '../../../data-fetching/useActiveOffers';

interface Props {
  item: CartLine;
  visible?: boolean;
  updateCart?: () => void;
}

export const CartItem: React.FC<Props> = ({ item }) => {
  const { addItemToWishlist, itemIsInWishlist } = useContext(WishlistContext); //?
  const { removeFromCart, setQuantity } = useCartLine(item.merchandise.id);
  const [isLoading, setIsLoading] = useState(false);

  const isAvailable = item.merchandise.availableForSale;

  //below price ternary as shopify seems to set price to zero when item sells?
  const price = isAvailable ? parseFloat(item.cost.totalAmount.amount) : parseFloat(item.merchandise.price.amount);
  const compareAtPrice = item.merchandise.compareAtPrice ? parseFloat(item.merchandise.compareAtPrice.amount) : parseFloat(item.merchandise.price.amount);
  const discount = roundNumberDownToNearest5(Math.round(((compareAtPrice - price) / compareAtPrice) * 100));


  const config = useStoryblokConfig();
  let currentPromo = (config?.promo_tags ?? [])
    .map(storyblokPromo => ({
      collection: `${storyblokPromo.department}-${storyblokPromo.collection_name.trim().replace(/\s/g, "-").toLowerCase()}`,
      label: storyblokPromo.label_text,
    }))
    .find(promo => item.canonicalItem?.collections.includes(promo.collection))?.label;

  const url = `/products/${item.canonicalItem?.handle}`;
  const offer = useActiveOfferForItem(item.canonicalItem?.sku);
  if (offer && !offerHasExpired(offer)) {
    currentPromo = `Offer expires ${daysDiffToString(daysRemainingForOffer(offer))}`;
  }
  return (
    <div key={item.id} className={classNames(styles.list, { [styles.listLoading]: isLoading })}>
      <div className={styles.imageCont}>
        <Link href={url} className="forced-aspect-square block">
          {item.merchandise.image ? <Image src={item.merchandise.image.url} alt={item.merchandise.image.altText ?? item.merchandise.product.title} className={styles.image} /> : null}
        </Link>
        {!isAvailable && <div className={classNames('display-10-caps', styles.soldOut)}>Sold out</div>}
      </div>

      <div className={styles.itemCont}>
        <Link href={url}>
          <p className="body-14 block md:hidden">{(item.canonicalItem) ? toPDPtitle(item.canonicalItem) : item.merchandise.product.title}</p>
          <p className="body-14 hidden md:block">{(item.canonicalItem) ? toPDPtitle(item.canonicalItem) : item.merchandise.product.title}</p>
        </Link>
        <p className="body-14">{item.canonicalItem?.size.display}</p>

        <p className="body-14-bold whitespace-pre-line">
          {IntlUtility.formatPrice({
            amount: price,
            currencyCode: item.cost.totalAmount.currencyCode,
          })}
          {(offer && !offerHasExpired(offer) && offer.offer_price === price ) ? (
            <span className={'line-through pl-1 text-black50'}>
              {IntlUtility.formatPrice({
                amount: parseFloat(item.merchandise.price.amount),
                currencyCode: item.cost.totalAmount.currencyCode,
              })}
            </span>
          ) : null}
          {(compareAtPrice && compareAtPrice !== price ) ? (
            <span className={'line-through pl-1 text-black50'}>
              {IntlUtility.formatPrice({
                amount: compareAtPrice,
                currencyCode: item.cost.totalAmount.currencyCode,
              })}
            </span>
          ) : null}
        </p>
        {currentPromo ? (
          <span className={'body-14-bold text-brandRed font-family: italic'}>{currentPromo}</span>
        ) : null}
        {!currentPromo && discount >= 50 && (
          <span className={'body-14-bold text-brandRed font-family: italic'}>Last chance</span>
        )}
      </div>


        <div className={styles.btnCol}>
            <button className="body-12 mb-1 text-right whitespace-nowrap" onClick={() => removeFromCart && removeFromCart()} >
              Remove
            </button>
            {isAvailable && item.canonicalItem && !itemIsInWishlist(item.canonicalItem) && (
              <button className="body-12 underline mb-1 text-right whitespace-nowrap" onClick={async () => {
                if (!item.canonicalItem) return;
                await addItemToWishlist(item.canonicalItem).then(() => {
                  return removeFromCart && removeFromCart();
                });
              }}>
                Save for later
              </button>
            )}
        </div>
      </div>
  );
};